.tileBox {
  width: 90%;
  height: fit-content;
  border-radius: 10px;
  padding: 10px;
  background-color: #cedebd;
}
.titkeCustom {
  height: fit-content;
  border-radius: 10px;
  width: 75%;
  background: linear-gradient(0deg, rgba(248, 241, 220, 1) 0%, rgba(255, 229, 150, 1) 100%);
}
.queryContainer {
  height: fit-content;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  background-color: #134e4a;
}
.dropdown {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cedebd;
}
#tilePara {
  line-height: 2;
  color: #333 !important;
}
.custombutton {
  background-color: #ece5fb !important;
  color: #333 !important;
  transition: background-color 0.3s ease, color 0.5s ease;
  border: 1px solid #d6c3fe !important;
  border-radius: 4px;
}
.btnChat {
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.5s ease;
}
.btnChat:hover {
  opacity: 0.8;
}

.custombutton:hover {
  /* background-color: #000 !important; */
  /* color: #fff !important; */
  opacity: 0.8;
}

.custombutton:hover .rightarrow {
  color: #fefffe !important;
  transition: color 0.5s ease;
}

.rightarrow {
  color: #fff !important;
}

.queryBox {
  width: 100%;
  height: fit-content;
  background: #edfde3;
}
.footerBtn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  /* padding: 10px;
  border: 1px solid #f4f4f4;
  border-radius: 4px; */
}
.editQuery {
  background-color: #c7e8ca !important;
  color: #2d5e52 !important;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.editQuery:hover {
  background-color: #9dc08b !important;
  color: #fefffe !important;
}

.filterIcon {
  color: #2d5e52 !important;
}

.dropInline {
  background: none;
  display: inline-block;
}
.noBg {
  background: none !important;
  color: #fb923c !important;
  font-size: 16px !important;
  padding-left: 2px !important;
  padding-right: 5px !important;
  padding: 3px;
  border-radius: 5px;
}
.noBg::after {
  color: #fb923c !important;
}
.noBg:hover {
  background: #fff6ef !important;
  cursor: pointer;
}
.dropInline .btn-link:hover {
  background: #b8d2f9 !important;
}
.queryFields {
  background: #d6c3fe !important;
  color: blue;
}
