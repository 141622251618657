#skills{
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* .listInfo{background: #f4f9fc;
    padding: 2px 8px;
    border-radius: 9px;
    margin-top: 0px !important;
    color: #1b5ec7 !important;}
.listInfo{background: #f4f9fc;
    padding: 2px 8px;
    border-radius: 9px;
    margin-top: 0px !important;
    color: #1b5ec7 !important;} */