//
// Buttons Base
//

// Button
.btn {
  --#{$prefix}btn-color: var(--#{$prefix}body-color);

  // Reset outline
  outline: none !important;

  // Reset focus shadow
  &:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
    box-shadow: none;
  }

  // Remove border
  &:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(#{$btn-padding-y} + #{$btn-border-width})
      calc(#{$btn-padding-x} + #{$btn-border-width});

    &.btn-lg {
      padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width})
        calc(#{$btn-padding-x-lg} + #{$btn-border-width});
    }

    &.btn-sm {
      padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width})
        calc(#{$btn-padding-x-sm} + #{$btn-border-width});
    }
  }

  // Link
  &.btn-link {
    border: 0;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;
    font-weight: $btn-font-weight;
  }

  // Outline
  &.btn-outline:not(.btn-outline-dashed) {
    border: 1px solid $input-border-color;
  }

  // Outline dashed
  &.btn-outline-dashed {
    border: 1px dashed $input-border-color;
  }

  // Flush
  &.btn-flush {
    @include button-reset();
  }

  // Flex
  &.btn-flex {
    display: inline-flex;
    align-items: center;
  }

  // Align start
  &.btn-trim-start {
    justify-content: flex-start !important;
    padding-left: 0 !important;
  }

  // Align start
  &.btn-trim-end {
    justify-content: flex-end !important;
    padding-right: 0 !important;
  }
}

// Reset
.btn-reset {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  user-select: none;
  outline: none;
}

.show > .btn.btn-primary.aditional-btn{ background:none !important;}
.btn.btn-primary.aditional-btn:hover:not(.btn-active), .btn.btn-primary.aditional-btn:focus:not(.btn-active), .btn.btn-primary.aditional-btn:active:not(.btn-active){ background:none !important;}
.btn-prime, .btn-prime:hover{ background: #ff715b !important; color:#fff !important}

.btn-started, .btn-started:hover{ background: #38c0de !important; color:#fff !important}
.color-white{ color: #fff !important;}
.btn-nobg{ border:1px solid #6f36e8 ; background:#6f36e8 ; color: #fff !important; border-radius: 6px; padding: 0px 15px;}
.btn-nobg i{ color: #fff !important;}
.btn-nobg:hover{ background: #6f36e8  !important; color: #fff !important; opacity: 0.8;}
.btn-nobg:hover i{color: #fff !important;}
.color-LightBlack{ color: #333 !important;}
.height-35{ height: 35px !important;}
// .bg-green{ border:1px solid #eacefb !important; }
// .bg-yellow{ border:1px solid #e0b2fb !important; }
 .bg-red div{ color: #9aa1b7;
  // border:1px solid #d18bfa !important; 
}
.bg-green div span.mt-1{color: #4a9e86;}
.bg-yellow div span.mt-1{color: #3991f9;}
.color-green{ color: #4a9e86 !important;}
.color-yellow{ color: #3991f9  !important;}
.color-red{ color: #f4b440 !important;}

.fw-light{ font-weight: 300 !important;}
.fw-regular{ font-weight: 400 !important;}
// Icons
.btn {
  // Font icon
  > i {
    display: inline-flex;
    font-size: $font-size-base;
    padding-right: 0.35rem;
    vertical-align: middle;
    //line-height: 0;
  }

  // Icon only button
  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: $input-height;
    width: $input-height;
    line-height: 1;

    // Remove border
    &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
      border: 0;
    }

    // Sizes
    &.btn-sm {
      height: $input-height-sm;
      width: $input-height-sm;
    }

    &.btn-lg {
      height: $input-height-lg;
      width: $input-height-lg;
    }

    &.btn-circle {
      border-radius: 50%;
    }
  }
}
