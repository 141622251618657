.container {
  width: 100%;
  height: fit-content;
  overflow-y: scroll;
}
#msg:hover {
  cursor: pointer;
}
#messageSelect {
  margin-top: 10px;
  width: 26vw;
  padding: 10px;
  min-height: 15vh;
  max-height: 25vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
#subject {
  color: #0c356a;
  border-bottom: 1px solid #aeb7c4;
  padding: 5px 0 5px 0;
}
#svgContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
#noMsg {
  width: 70%;
  height: auto;
}
#newEmail {
  height: 30px;
  display: flex;
  align-items: center;
  width: 110px;
  font-size: 0.75em;
}
#emailContainer {
  width: 100%;
}
.senderMail{
  width: 31vw;
}
.bodyContainer{
  width: 30vw;
}
.emailcardfooter{
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
}