.timelineContainer {
  padding: 20px;

}
.timelineCard {
  height: 10vh;
  margin-left: 10px;
  width: 82%;
 border:0px !Important;
 box-shadow: none;
}
.cardcontent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding:0px 10px 10px 10px;
  position: relative;
  top: -9px;
}
#timelinelabel::before {
  content: '';
  display: block;
  width: 3px;
  height: 100%;
  background-color: #ededf0;
  position: absolute;
  top: 0.2%;
  left: -1px;
}
