// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  appearance: none;
  // border:1px solid #7239ea;
  //box-shadow: none !important;

  .form-select:focus{ 
    // border:1px solid #7239ea;
  }
  &:focus {
    border-color: $form-select-focus-border-color;
    @if $enable-shadows {
      @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  &:disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    text-shadow: 0 0 0 $form-select-color;
  }

  // Solid style
  &.form-select-solid {
    @include placeholder($input-solid-placeholder-color);
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    color: $input-solid-color;
    transition: $transition-input;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus !important;
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // Transparent style
  &.form-select-transparent {
    @include placeholder($input-placeholder-color);
    background-color: transparent;
    border-color: transparent;
    color: $input-color;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: transparent;
      border-color: transparent !important;
      color: $input-color;
    }
  }
}
