#newTeamCard {
  width: 40%;
  height: 50vh;
  position: absolute;
  z-index: 10;
  right: 30%;
  top: 20%;
  /* background: rgb(253, 214, 226);
    background: linear-gradient(90deg, rgba(253, 214, 226, 1) 0%, rgba(255, 247, 250, 1) 100%); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 7px 7px 7px 7px;
  padding: 5px;
  overflow-y: scroll;
}
.teamSettingsContainer {
  padding: 10px;
  height: 100%;
  width: 100%;
  background: white;
}
.teamTable {
  /* display: flex; */
  width: 100%;
  height: 100%;
  /* justify-content: center; */
}
.creditCol {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.confirmBtns {
  display: flex;
  gap: 5px;
}
.editIcon {
  color: #204f58;
  font-size: 0.8em;
}
.teamInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.Upgrade {
  background: #87255b;
}
.Upgrade:hover {
  background: #b43279 !important;
}
.TableContainer {
  display: flex;
  flex-direction: column;
}
