#tile1Card,
#tile2Card,
#tile3Card {
  width: 31%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cedebd;
  transition: background-color 0.3s ease;
  border: 0px;
  cursor: pointer;
}
#tile1Card h2,
#tile2Card h2,
#tile3Card h2 {
  border: 0px;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
}
#tile1Card {
  background: teal;
}
#tile2Card {
  background: teal;
}
#tile3Card {
  background: teal;
}

#tile1Card:hover {
  cursor: pointer;
  color: black;
  background-color: teal;
}
#tile2Card:hover {
  cursor: pointer;
  color: black;
  background-color: teal;
}
#tile3Card:hover {
  cursor: pointer;
  color: black;
  background-color: teal;
}
/* #tile1Card:hover h2 {
  color: black;
}
#tile2Card:hover h2 {
  color: black;
}
#tile3Card:hover h2 {
  color: black;
} */

.tileIcons{
  color: rgb(255, 255, 255) !important;
}