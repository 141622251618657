#type {
  color: rgb(0, 0, 0, 0.7);
}
#flowcard:hover #type {
  /* color: white; */
}
#flowcard span {
  color: rgb(0, 0, 0, 0.4);
}
#flowcard {
  cursor: pointer;
  border-radius: 5px;
}
#flowcard:hover span {
  /* color: white; */
}
.inputStyle {
  background: none !important;
  border: 1px solid #efeded !important;
  border-radius: 6px;
}
.templateCard {
  height: 95vh;
  margin-bottom: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: row;
}
.flowArea {
  width: 50%;
}
.flowtemplate {
  width: 50%;
  padding: 10px;
  overflow-y: scroll;
}
.emailcard {
  padding: 10px 20px 10px 20px;
  height: 93vh;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.callCard {
  padding: 10px 20px 10px 20px;
  height: 90vh;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.linkedIn {
  padding: 10px 20px 10px 20px;
  height: 92vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.emailcardfooter {
  width: 100%;
  height: fit-content;
  display: flex;
}
#braces {
  transition: transform 0.3s ease;
}
#braces:hover {
  cursor: pointer;
  transform: scale(1.1);
}
#bracevalues {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 0;
}

#flowcard {
  width: 25%;
}
#templateCard {
  width: 95%;
  min-height: 50vh;
  justify-content: space-between;
  position: absolute;
  left: 3%;
  z-index: 50;
  top: 35%;
  display: flex;
  flex-direction: column;
}
.templatecategory {
  width: 30%;
  max-height: 40vh;
  overflow-y: scroll;
  padding: 2%;
}
.template {
  width: 45%;
  border-left: 1px solid black;
  padding-left: 1%;
  padding-top: 2%;
}
.templatepreview {
  width: 25%;
  border-left: 1px solid black;
  padding: 2%;
}
#promptList {
  display: flex;
  flex-direction: column !important;
  column-gap: 100px !important;
}
#prompt {
  padding-left: 2%;
}
#prompt:hover {
  border-left: 5px solid rgb(11, 248, 11);
}
.previewCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.previewRightContainer {
  width: 30%;
  height: 80vh;
  overflow-y: scroll;
}
.previewLeftContainer {
  width: 70%;
  height: 80vh;
  overflow-y: scroll;
}
.noProfilesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10%;
  height: 100%;
  border-left: 1px solid #d5d5d5;
}
.noprofileSvg {
  width: 65%;
}
.noFlowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}
.noprofilesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  gap: 10px;
}
#noFlowIcon {
  font-size: 5em;
  color: #f1416c;
}
.profileCard {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 2%;
}
.profileCard:hover {
  cursor: pointer;
  border-left: 2px solid green;
}

#tabItem {
  color: #204f58;
}
#tabItem::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 0;
  background-color: #f9856e;
  animation: expandHighlight 0.8s ease forwards;
}

@keyframes expandHighlight {
  0% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 100%;
  }
}
