.nextBtn,.prevBtn {
  background-color: white;
  border: none;
  width: fit-content;
  color: #333;
}

.nextBtn i,.prevBtn i{ color: #333;}

#nextIcon {
  color: blueviolet;
  transition: color 0.2s ease;
}
#nextIcon:hover{
    color: #9681EB;
}
#prevIcon{
    color: #213555;
    transition: color 0.2s ease;
}
#prevIcon:hover{
    color: #4F709C;
}
.disabledBtn{
    color: #ccc;
}
.disabledBtn i{ color: #ccc;}
#closeIcon{
    color: black;
    transition: color 0.3s ease;
    font-weight: bold;
    font-size: larger;
}
#closeIcon:hover{
    color: red;
}