.mainContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  
  transition: grid-template-columns 0.5s ease;
}

.dashstyle.smallChatContainer {
  grid-template-columns: 60% 40%;
}

.chatContainer {
  width: 100%;
}

.resultContainer {
  width: 100%;
  height: 100%;

  padding: 1rem;
}
#queryCard {
  position: absolute;
  padding: 20px;
  z-index: 100;
  top: 5%;
  left: 20%;
  width: 60%;
  height: 90%;
  overflow-y: scroll;
}
.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgb(0, 0, 0, 0.35);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
#firstCard,
#seventhcard {
  width: 100%;
  height: fit-content;
  /* overflow-y: scroll; */
}
#secondCard {
  width: 100%;
  min-height: 20vh;
  max-height: fit-content;
  overflow-y: scroll;
  background-color: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 15px;
  border: 0px;
  /* background: #faf9fc; */
  border: 1px solid #ecebeb;
  border-radius: 4px;
}
#fourthCard {
  width: 100%;
  min-height: 20vh;
  overflow-y: scroll;
  background-color: none;
  display: flex;
  flex-direction: column;
  padding: 12px 15px;
  border: 0px;
  /* background: #faf9fc; */
  border: 1px solid #ecebeb;
  border-radius: 4px;
}
.slidercont {
  padding: 12px 15px;
  /* background: #faf9fc; */
  border: 1px solid #ecebeb;
  border-radius: 4px;
}
.country {
  width: 100%;
  margin-top: 8px;
}
.checkOne:checked {
  background-color: #7239ea;
}
.countrytextField {
  width: 100%;
}
.companiesContainer,
.IndustryContainer,
.degreecontainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-top: 5px;
}
.countrynamescont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.languageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px;
}
.companynames {
  width: 50%;
}
.companybox,
.industrybox {
  width: auto;
  /* background-color: #bfa2fc; */
  /* color:#fff; */
  margin-top: 5px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;

  font-size: 12px;
  color: #6f36e8;
  background: none;
  border: 1px solid #6f36e8;
}
.languagebox {
  width: auto;
  /* background-color: #bfa2fc; */
  /* color: #fff; */
  margin-top: 5px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 12px;
  color: #6f36e8;
  background: none;
  border: 1px solid #6f36e8;
}
.countrybox {
  width: 40%;
  background-color: #93c5ff;
  color: #000;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}
.degreebox {
  width: auto;
  /* background-color: #bfa2fc; */
  /* color: #fff; */
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;

  font-size: 12px;
  color: #6f36e8;
  background: none;
  border: 1px solid #6f36e8;
}
#companiesWorked {
  display: flex;
  align-items: center;
  /* width: fit-content; */
  height: 40px;
  /* position: relative; */
  left: 5px;
  color: #fff;
  /* background-color: #134e4a; */
  /* border:1px solid #134e4a; */
}
#companiesWorked:hover {
  cursor: pointer;
}
.clear {
  transition: transform 0.3s ease;
  margin-top: 20px;
}
.clear:hover {
  cursor: pointer;
  transform: translateY(-1px);
  color: rgb(255, 74, 74);
}
.countrybox {
  width: auto;
  /* background-color: #bfa2fc; */
  /* color:#fff; */
  margin-right: 5px;
  margin-top: 5px;
  padding: 2px 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;

  font-size: 12px;
  color: #6f36e8;
  background: none;
  border: 1px solid #6f36e8;
}
.close {
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 100%;
  color: white;
}
.close:hover {
  transform: translateY(-0.5px);
  border-radius: 100%;
  color: white;
}
.expInput {
  width: 100%;
}
.expcont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 15px;
}

#searchIndustryCard,
#searchUniversityCard,
#searchDegreeCard {
  min-height: 10vh;
  max-height: 20vh;
  overflow-y: scroll;
  width: 40%;
  padding: 5px;
}
#searchCompanyCard{
  min-height: 10vh;
  max-height: 20vh;
  overflow-y: scroll;
  width: 40%;
  padding: 5px;
}
.searchcompanies,
.searchUniversities,
.searchDegree {
  border: 1px solid #dde1ec;
  background-color: none;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  width: 40%;
}
.searchUniversities:focus,
.searchDegree:focus {
  outline: 1px solid rgb(104, 104, 104);
}
.searchcompanies:focus {
  outline: 1px solid rgb(104, 104, 104);
}
.companySelect,
.degreeSelect {
  cursor: pointer;
  /* border-bottom: 1px solid #cccccc; */
  margin-bottom: 8px;
  transition: background-color 0.5s ease;
  border-radius: 5px;
  padding: 5px;
}
.companySelect:hover {
  background-color: #cccccc;
}
.profileimg {
  width: 20px;
  margin-right: 10px;
}
.jobtitlecontainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-top: 10px;
}
.titlebox {
  width: auto;
  /* background-color: #bfa2fc; */
  /* color: #fff; */
  margin-top: 0px;
  padding: 2px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  font-size: 12px;
  color: #6f36e8;
  background: none;
  border: 1px solid #6f36e8;
}
#titleselect:hover {
  cursor: pointer;
}
#searchDegreeCard {
  top: 139vh;
}
.degreeSelect:hover {
  background-color: #cccccc;
}
@media (max-width: 768px) {
  .mainContainer {
    grid-template-columns: 1fr;
  }
  .chatContainer {
    width: 100%;
    border: 1px solid black;
    height: fit-content;
  }
}
