#templateCard{
    position: absolute;
    padding: 20px;
    z-index: 100;
    bottom: 20%;
    left: 20%;
    width: 60%;
    height: 90%;
    overflow-y: scroll;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
    background-color: #F0F0F0;
}
#eachTeamplateAdd:hover{
  cursor: pointer;
}
.tempName,.subject,.content{
    border-bottom: 1px solid #D8D9DA;
    padding: 10px;
}
.tempName{
  background-color: teal;
  color: white;
}
#templateName:hover{
  cursor: pointer;
  
}
#templateName{
  padding: 10px;
  border-radius: 5px;
}
.selectedkey {
  background-color: #0E21A0; 
  transition: background-color 0.5s ease; 
  color: #E4F1FF;
  
}
