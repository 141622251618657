.ant-btn-primary {
  background-color: #f0795b;
}
.ant-btn-primary:disabled:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #f0795b;
  background: #f9856e;
}

.ant-modal .ant-modal-content,
.ant-modal .ant-modal-title {
  background-color: #fff;
}

.ant-btn-default {
  border-color: #f5222d;
  color: #f5222d;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #f5222d;
  background-color: #fff9f9d6;
  color: #f5222d;
}
.ant-layout .ant-layout-header {
  background: white;
}
.ant-segmented .ant-segmented-item-selected {
  background: #f9856e;
  color: white;
}
.ant-segmented .ant-segmented-group {
  color: black;
}
label {
  margin-bottom: 0;
}
.ant-drawer .ant-drawer-body {
  padding: 10px;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px teal;
}
.ant-slider .ant-slider-handle.ant-tooltip-open::after {
  box-shadow: 0 0 0 4px teal !important;
}
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px teal !important;
}
.ReactQueryDevtools {
  display: none;
}
.ant-table-wrapper .ant-table {
  font-family: 'Source Sans Pro';
}
span,
.ant-tabs,
label,
button {
  font-family: 'Source Sans Pro';
}
.ant-btn-link,
.ant-btn-link > span {
  color: #204f58;
  font-weight: 600;
}
.ant-btn-link:hover,
.ant-btn-link > span:hover {
  color: #2f7481;
  font-weight: 600;
}
.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #2f7481;
  background: transparent;
}
body {
  font-size: 0.9rem;
}
.heading,
.ant-tabs > .ant-tabs-nav,
.ant-table-thead {
  font-size: 1.06rem;
  font-family: 'Lato', sans-serif;
}
.ant-list {
  width: 100%;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
  width: fit-content;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 80px;
  height: 80px;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-container {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-block: 0 0px;
  margin-inline: 0 8px;
  vertical-align: top;
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}
.ant-form-item .ant-form-item-label {
  text-align: start;
}

.ant-tour .ant-tour-content {
  width: 60% !important;
}
.PriceColumn {
  width: auto;
}
.ant-progress .ant-progress-bg {
  background: #204f58;
}
.ant-tabs .ant-tabs-content {
  height: 100%;
}
.ant-layout,
.ant-layout-footer {
  background-color: white;
}

.ant-tag {
  margin-inline-end: 0px;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-spin {
  color: #204f58;
}
.ant-tabs .ant-tabs-tab-btn .ant-tabs-tab-icon:not(:last-child) {
  margin-inline-end: 7px;
}
.ant-menu-dark {
  background-color: #204f58;
  color: #d9d9d9;
  font-family: 'Source Sans Pro';
}
.ant-tabs .ant-tabs-ink-bar {
  color: #f0795b !important;
}
.ant-menu-vertical .ant-menu-item {
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  padding: 12px 32px !important;
}
.ant-menu-vertical .ant-menu-item {
  gap: 3px !important;
}
.ant-menu .ant-menu-item .ant-menu-item-icon {
  min-width: 20px !important;
}
.ant-menu-title-content {
  width: 100% !important;
}
.ant-menu-vertical .ant-menu-item {
  margin-inline: 0 !important;
}
.popover-menu-item > .ant-menu-title-content{
  opacity: 1 !important;
}
#root{
  height: 100%;
  background-color: #fff;
}