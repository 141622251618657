#chatborder {

  /* background-image: linear-gradient(to right, grey, transparent);
  background-size: 100% 1px;
  background-repeat: no-repeat; */
  resize: none;
  padding-right: 125px;
  padding-left: 10px;
  /* min-height: 10vh; */
  border: 1px solid rgb(236, 239, 237);
  background: #fff;
  border-radius: 6px;
  min-height: '60px';
  max-height: '100px';
  line-height: 24px;
  font-family: 'Karla', sans-serif;
}
.chatFooter {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 80px;
  width: 90%;
}
.beforeChatStart {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#sendBtn {
  position: absolute;
  top: 17px;
  right: 24px;
  transform: rotate(42deg);
  font-size: 2em;
  color: #333;
}

#initialBtn {
  position: absolute;
  top: 36%;
  left: 3%;
  font-size: 2em;
  /* color: #fb923c ; */
}

#sendBtn:hover {
  cursor: pointer;
  color: #333;
}
